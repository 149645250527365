<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4"></div>
    </base-header>

    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <h3 class="mb-0">Danh sách từ khoá</h3>
          </template>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary pagination-select"
                v-model="listQuery.limit"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div>
                <base-input
                  v-model="listQuery.search"
                  prepend-icon="fas fa-search"
                  placeholder="Tìm kiếm..."
                >
                </base-input>
              </div>
            </div>

            <el-table
              :data="tableData"
              row-key="id"
              header-row-class-name="thead-light"
              v-loading="listLoading"
              @sort-change="sortChange"
              @selection-change="selectionChange"
            >
              <el-table-column
                v-bind="{
                  label: 'Từ khóa',
                  minWidth: 160,
                  sortable: true,
                }"
              >
                <template slot-scope="scope">
                  <router-link
                    :to="{ name: 'ViewTerm', params: { id: scope.row.id } }"
                    >{{ scope.row.keyword }}</router-link
                  >
                </template>
              </el-table-column>

              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>

              <el-table-column label="Trạng thái">
                <template slot-scope="scope">
                  <base-switch
                    class="mr-1"
                    v-model="scope.row.is_active"
                    type="success"
                    @input="updateTerm(scope.row)"
                  ></base-switch>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">Tổng cộng: {{ pagination.total }}</p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "@/components";
import Errors from "@/utils/error";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      tableColumns: [
        {
          prop: "location_name",
          label: "Vị trí",
          minWidth: 220,
          sortable: true,
        },
        {
          prop: "language_name",
          label: "Ngôn ngữ",
          minWidth: 150,
          sortable: true,
        },
        {
          prop: "updated_at",
          label: "Cập nhật",
          minWidth: 150,
          sortable: true,
        },
      ],
      tableData: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
      },
      listQuery: {
        page: 1,
        limit: 10,
        search: undefined,
        sortBy: "created_at",
      },
      listLoading: false,
      urls: [],
    };
  },
  created() {
    this.getList();
  },
  watch: {
    "listQuery.limit": {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    async getList() {
      this.listLoading = true;
      this.listQuery.page = this.pagination.currentPage;
      const { data, pagination } = await this.$store.dispatch(
        "term/fetchTerms",
        this.listQuery
      );

      this.tableData = data;
      this.pagination.total = pagination.total;
      this.pagination.currentPage = pagination.from;
      this.listQuery.page = pagination.from;
      this.listLoading = false;
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    sortChange({ prop, order }) {
      if (prop) {
        this.tableData.sort((a, b) => {
          let aVal = a[prop];
          let bVal = b[prop];
          if (order === "ascending") {
            return aVal > bVal ? 1 : -1;
          }
          return bVal - aVal ? 1 : -1;
        });
      } else {
        this.tableData.sort((a, b) => {
          return a.id - b.id;
        });
      }
    },
    async updateTerm(row) {
      let self = this;
      this.formLoading = true;
      this.errors = new Errors();
      this.errText = "";

      try {
        const response = await this.$store.dispatch("term/updateTerm", row);

        this.showDetailModal = false;
        this.$notify({
          message: response.status
            ? "Cập nhật thông tin api thành công!"
            : "Cập nhật thông tin không thành công!",
          timeout: 2000,
          icon: "ni ni-bell-55",
          type: response.status ? "success" : "danger",
          verticalAlign: "bottom",
          horizontalAlign: "right",
        });
      } catch (err) {
        if (err.response.status === 422) {
          self.errors.record(err.response.data);
        }
      }
      this.formLoading = false;
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
